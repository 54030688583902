module services {
    export module logistics {
        export class commercialInvoiceSundryItemService implements interfaces.logistics.ICommercialInvoiceSundryItemService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            delete(cisId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CommercialInvoiceSundryItem/Delete", {
                    cisId: cisId
                });
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CommercialInvoiceSundryItem/Save");
            }

            get(cisId: number, IsCostInvoice: boolean, comId?: number): ng.resource.IResourceClass<interfaces.logistics.ICommercialInvoiceSundryItem> {
                return this.$resource<interfaces.logistics.ICommercialInvoiceSundryItem>(this.ENV.DSP_URL + "CommercialInvoiceSundryItem/Get", {
                    cisId: cisId,
                    IsCostInvoice: IsCostInvoice,
                    comId: comId
                }, {
                    get: {
                        method: "GET",
                        isArray: false,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                    }
                });
            }
        }
    }
    angular.module("app").service("commercialInvoiceSundryItemService", services.logistics.commercialInvoiceSundryItemService);
}